.main{
  width: 100%;
  height: 100%;
}


.navbar-brand,
.navbar-nav .nav-link {
  color: black;
  font-size: 20px;
  margin-left: 20px;
}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: red;
  font-weight: bold;
}

.navigate {
  position: sticky;
  top: 0;
  z-index: 999;
}

.head {
  width: 100%;
  background-color: linen;
  height: 40px;
  display: flex;
}

.head2 {
  width: 33%;
  height: 100%;
  margin-left: 10px;
}

.head2 p {
  color: black;
  margin-left: 0px;
  padding-top: 04px;
  font-weight: bold;
}


.appoint {
  color: black;
  border-radius: 16px;
  margin-left: 10px;
  background-color: lightcyan;
  text-align: center;
}


.header {

  height: 450px;
  width: 100%;
}

.carousel-item img {
  width: 100%;
  height: 450px;
}

.contai {
  width: 99%;
  height: 500px;
  border: 1px solid lightgray;
  margin-top: 20px;
  border-radius: 16px;
  margin-left: 05px;
}

.contai h2 {
  text-align: center;
  padding-top: 40px;
  font-weight: bold;
}

.contai h1 {
  margin-left: 300px;
  padding-top: 10px;
  font-size: 30px;
}

.contai p {
  margin-left: 50px;
  font-size: 17px;
  margin-top: 20px;
}

.links2 {
  margin-left: 450px;
  margin-top: 60px;
}

.links2 button {
  margin-left: 20px;
}


.fract {
  width: 100%;
  height: 400px;
  display: flex;
  margin-top: 80px;
}

.fract2 {
  width: 48%;
  height: 400px;
  margin-left: 20px;

  border-radius: 10px;
}

.fract2 img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.depart {
  width: 100%;
  height: 300px;

  margin-top: 50px;
  display: flex;
}

.depart2 {
  width: 30%;
  height: 100%;
  border: 1px solid green;
  margin-left: 30px;
  border-radius: 16px;
}

.depart2 img {
  height: 100px;
  margin-left: 30%;
  padding-top: 10px;
}

.depart2 h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 10px;
}

.depart2 p {
  margin-left: 10px;
  padding-top: 10px;

}

.gallery {

  width: 100%;
  height: 300px;
  display: flex;
  margin-top: 50px;
}

.gallery2 {
  width: 25%;
  height: 100%;

}


.gallery2 img {
  width: 100%;
  height: 100%;
}

.footer {
  margin-top: 100px;
}

.facility {
  width: 80%;
  border: 1px solid lightgray;
  height: 100%;
  margin-left: 130px;
  margin-top: 70px;
}

.facility p {
  margin-left: 80px;
  padding-top: 10px;
  font-size: 18px;
}







@media screen and (max-width: 700px) {

  .navigate {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .head {
    width: 100%;
    background-color: linen;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .head2 {
    width: 100%;
    height: 30px;
    margin-left: 0px;
  }

  .head2 p {
    color: black;
    margin-left: 10px;
    padding-top: 04px;
    font-weight: bold;
    font-size: 13px;
  }



  .head2 {
    width: 100%;
    /* Make it full width on smaller screens */
    margin-left: 0;
  }

  .header {

    height: 100%;
    width: 100%;
  }

  .header h3 {
    color: black;
  }

  .header p {
    color: black;
  }

  .carousel-item img {
    width: 100%;
    height: 250px;
  }

  .contai {
    width: 99%;
    height: 100%;
    border: 1px solid lightgray;
    margin-top: 70px;
    border-radius: 16px;
    margin-left: 02px;
  }

  .contai h1,
  .contai p {
    margin-left: 2%;
  }

  .links2 {
    margin-left: 5%;
  }

  .links2 button {
    margin-left: 10px;
  }

  .fract2 {
    width: 100%;
    height: 250px;
  }


  .fract {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 80px;
    flex-direction: column;
  }

  .fract2 {
    width: 100%;
    height: 300px;
    margin-left: 0px;
    border-radius: 10px;
    margin-top: 15px;
  }

  .fract2 img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }


  .depart {
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-top: 20px;
    display: flex;
  }

  .depart2 {
    width: 95%;
    height: 300px;
    border: 1px solid green;
    margin-left: 07px;
    border-radius: 16px;
    margin-top: 15px;
  }

  .depart2 img {
    height: 100px;
    margin-left: 30%;
    padding-top: 10px;
  }

  .depart2 h1 {
    text-align: center;
    font-size: 30px;
    margin-top: 10px;
  }

  .depart2 p {
    margin-left: 10px;
    padding-top: 10px;

  }


  .gallery {

    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 50px;
    flex-direction: column;
  }

  .gallery2 {
    width: 100%;
    height: 300px;
    margin-top: 15px;
  }


  .gallery2 img {
    width: 100%;
    height: 100%;
  }

  .footer {
    margin-top: 60px;
  }

  
.facility{
  width: 100%;
  border: 1px solid lightgray;
  height: 100%;
  margin-left: 0px;
  margin-top: 70px;
}

.facility p {
  margin-left: 10px;
  padding-top: 10px;
  font-size: 15px;
}


}


/* Add these styles to your existing stylesheet or create a new one */

.contact {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

label {
  margin-top: 10px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border: none;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 600px) {
  form {
    max-width: 100%;
  }
}




@media screen and (min-width: 700px) and (max-width: 1250px){
  .main{
    width: 1250px;
    height: 100%;
  }
  
  
  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 20px;
    margin-left: 20px;
  }
  
  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: red;
    font-weight: bold;
  }
  
  .navigate {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .head {
    width: 100%;
    background-color: linen;
    height: 40px;
    display: flex;
  }
  
  .head2 {
    width: 33%;
    height: 100%;
    margin-left: 10px;
  }
  
  .head2 p {
    color: black;
    margin-left: 0px;
    padding-top: 04px;
    font-weight: bold;
  }
  
  
  .appoint {
    color: black;
    border-radius: 16px;
    margin-left: 10px;
    background-color: lightcyan;
    text-align: center;
  }
  
  
  .header {
  
    height: 450px;
    width: 100%;
  }
  
  .carousel-item img {
    width: 100%;
    height: 450px;
  }
  
  .contai {
    width: 99%;
    height: 500px;
    border: 1px solid lightgray;
    margin-top: 20px;
    border-radius: 16px;
    margin-left: 05px;
  }
  
  .contai h2 {
    text-align: center;
    padding-top: 40px;
    font-weight: bold;
  }
  
  .contai h1 {
    margin-left: 300px;
    padding-top: 10px;
    font-size: 30px;
  }
  
  .contai p {
    margin-left: 50px;
    font-size: 17px;
    margin-top: 20px;
  }
  
  .links2 {
    margin-left: 450px;
    margin-top: 60px;
  }
  
  .links2 button {
    margin-left: 20px;
  }
  
  
  .fract {
    width: 100%;
    height: 400px;
    display: flex;
    margin-top: 80px;
  }
  
  .fract2 {
    width: 48%;
    height: 400px;
    margin-left: 20px;
  
    border-radius: 10px;
  }
  
  .fract2 img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  .depart {
    width: 100%;
    height: 300px;
  
    margin-top: 50px;
    display: flex;
  }
  
  .depart2 {
    width: 30%;
    height: 100%;
    border: 1px solid green;
    margin-left: 30px;
    border-radius: 16px;
  }
  
  .depart2 img {
    height: 100px;
    margin-left: 30%;
    padding-top: 10px;
  }
  
  .depart2 h1 {
    text-align: center;
    font-size: 30px;
    margin-top: 10px;
  }
  
  .depart2 p {
    margin-left: 10px;
    padding-top: 10px;
  
  }
  
  .gallery {
  
    width: 100%;
    height: 300px;
    display: flex;
    margin-top: 50px;
  }
  
  .gallery2 {
    width: 25%;
    height: 100%;
  
  }
  
  
  .gallery2 img {
    width: 100%;
    height: 100%;
  }
  
  .footer {
    margin-top: 100px;
  }
  
  .facility {
    width: 80%;
    border: 1px solid lightgray;
    height: 100%;
    margin-left: 130px;
    margin-top: 70px;
  }
  
  .facility p {
    margin-left: 80px;
    padding-top: 10px;
    font-size: 18px;
  }
   
}